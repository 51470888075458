import { PUBLIC_VAPID_KEY } from "../config"

const publicKey = PUBLIC_VAPID_KEY

if ('serviceWorker' in navigator) {
  //console.log('Registrando service worker')
  regWorker().catch(error =>
    console.warn('Error en el metodo regWorker:', error)
  )
}

async function regWorker() {
  await navigator.serviceWorker.register('worker.js', { scope: '/' })

  // SUBSCRIBE TO PUSH SERVER
  await navigator.serviceWorker.ready
    .then(async reg => {
      await reg.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: publicKey
        })
        .then(async sub => {
          // guardo en el storage las credenciales de la subscripcion
          localStorage.setItem('subscription', JSON.stringify(sub))
        })
        .catch(error => {
          console.error('Error al subscribirse:', error)
        })
    })
    .catch(error => {
      console.error('Error al leer el trabajador servicio:', error)
    })
}
