import config, { API_URL } from '../../../config'
import store from '../../store'

export default {
  namespaced: true,

  actions: {
    async getFamilias() {
      const response = await fetch(
        `${API_URL}/comprobantesAfip/empresasHabilitadas`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return response.json()
    },

    async enviarDatos({}, payload) {
      const params = new URLSearchParams({
        Empresa_id: payload.empresa
      })

      const response = await fetch(
        `${API_URL}/comprobantesAfip/subirComprobantes?${params.toString()}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload.data)
        }
      )

      return response.json()
    },

    async procesarDatos({}, payload) {
      const response = await fetch(`${API_URL}/comprobantesAfip/procesar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getComprobantes({}, payload) {
      const params = new URLSearchParams({
        Empresa_id: payload.empresa,
        fecha_desde: payload.fecha_desde,
        fecha_hasta: payload.fecha_hasta,
        sobrantes: payload.sobrantes
      })

      const response = await fetch(
        `${API_URL}/comprobantesAfip/comprobantes?${params.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return response.json()
    },

    async getEstados() {
      const response = await fetch(`${API_URL}/comprobantesAfip/estados`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async getTiposComprobantes() {
      const response = await fetch(
        `${API_URL}/comprobantesAfip/tiposComprobantes`,
        {
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          }
        }
      )

      return response.json()
    },

    async reprocesarFiltrados({}, payload) {
      const facturas = payload

      const response = await fetch(
        `${API_URL}/comprobantesAfip/reprocesarConjunto`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(facturas)
        }
      )

      return response.json()
    },

    async procesarDatosPortalIVA({}, payload) {
      const response = await fetch(
        `${API_URL}/comprobantesAfip/procesar-portal`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'Application/json',
            Authorization: store.state.token
          },
          body: JSON.stringify(payload)
        }
      )

      return response.json()
    },

    async getPermisos() {
      const response = await fetch(`${API_URL}/comprobantesAfip/permisos`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async anularFactura({}, payload) {
      const response = await fetch(`${API_URL}/comprobantesAfip/anular`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    }
  }
}
