import { API_URL } from '../../../config'
import store from '../../store'

export default {
  namespaced: true,
  actions: {
    async getReporte({}, payload) {
      const response = await fetch(`${API_URL}/reportes/generar`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },
    async getTiposReportes() {
      const response = await fetch(`${API_URL}/reportes/tipos`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })
      return response.json()
    }
  }
}
