import { API_URL } from '../../../../config'
import store from '../../../store'

export default {
  namespaced: true,
  actions: {
    async getClasificaciones({}, payload) {
      const response = await fetch(`${API_URL}/clasificaciones/clasificaciones-ventas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getVentasClasif({}, payload) {
      const response = await fetch(`${API_URL}/clasificaciones/ventas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getCuentas({}, payload) {
      const response = await fetch(`${API_URL}/clasificaciones/cuentas-ventas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async nuevaClasificacionVentas({}, payload) {
      const response = await fetch(`${API_URL}/clasificaciones/nueva-ventas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })
      return response.json()
    },

    async editarClasificacionVentas({}, payload) {
      const response = await fetch(`${API_URL}/clasificaciones/editar-ventas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },
  }
}
