<template>
  <v-app>
    <NavigationDrawer v-if="is_logged && $route.name != 'Login'" @change_size="redimensionar"
      @subir_vista="$vuetify.goTo(0)" />
    <v-main :style="imagen">
      <v-container fluid>

        <v-alert v-if="!permiso_notif && muestra_cartel" type="warning" class="ma-0 mb-3" style="cursor: default;"
          dense>
          <span class="body-1">
            <strong>Su navegador no tiene los permisos necesarios para recibir notificaciones.</strong>
            Si desea activarlos, vaya a la configuración de su navegador o haga click
            <span class="zelda" @click="modal_ayuda = true">aquí</span>.
          </span>
        </v-alert>

        <v-scroll-x-transition hide-on-leave>
          <router-view></router-view>
        </v-scroll-x-transition>
        <!-- alerta con estilo de snackbar -->
        <div @mouseover="reset_progress()" @mouseleave="start_progress()">
          <v-snackbar v-model="$store.state.snackbar.value" class="mb-4" elevation="12"
            transition="slide-y-reverse-transition" :timeout="$store.state.snackbar.timeout"
            :color="$store.state.snackbar.color">
            <span v-html="$store.state.snackbar.text" class="body-1 mb-4"></span>
            <v-progress-linear v-model="value" color="white" class="mb-n1 mt-3"
              style="margin-left: 22px"></v-progress-linear>
            <template v-slot:action="{ attrs }">
              <v-btn v-bind="attrs" class="mb-3" title="Cerrar" icon @click="$store.state.snackbar.value = false">
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>

        <!-- snackbar de notificaciones push -->
        <div v-if="notificaciones">
          <div v-for="(item, index) in notificacionesFiltradas" :key="index">
            <v-snackbar v-if="$route.name != 'Notificaciones'" v-model="item.value"
              transition="slide-x-reverse-transition" elevation="12" color="" class="mb-4"
              :style="`padding-bottom: ${(index * 151).toString()}px`" :timeout="-1" outlined right text>
              <v-card-title class="pa-0 mr-n2">
                <v-icon :color="item.Color" left>{{ item.Icono }}</v-icon>
                {{ item.Titulo }}
                <v-spacer></v-spacer>
                <v-btn small icon @click="notificacion_leida(item)">
                  <v-icon small>fas fa-times</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="px-0 pt-0 pb-3 mr-n2">
                {{ item.Mensaje }}
              </v-card-text>
              <v-card-actions class="d-flex justify-end pa-0 mr-n2">
                <v-btn color="info" small text @click="notificacion_leida(item, true)">
                  <v-icon small left>fas fa-eye</v-icon>
                  Ver
                </v-btn>
                <v-btn color="success" small text @click="notificacion_leida(item)">
                  <v-icon small left>fas fa-check-double</v-icon>
                  Leida
                </v-btn>
              </v-card-actions>
            </v-snackbar>
          </div>
        </div>
      </v-container>
    </v-main>
    <Footer v-if="$route.name == 'Login'" />
    <LoadingScreen />
    <AyudaNotificaciones v-model="modal_ayuda" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NavigationDrawer from './NavigationDrawer'
import Footer from './Footer'
import LoadingScreen from './components/LoadingScreen'
import moment from 'moment'
import AyudaNotificaciones from './components/AyudaNotificaciones.vue'
import config from '../config'

export default {
  data() {
    return {
      muestra_cartel: true,
      permiso_notif: false,
      modal_ayuda: false,
      value: 100,
      interval: 0,
      height: 0,
    }
  },
  async created() {

    if (config.API_URL.indexOf(`${config.DEV_URL}`) >= 0 || config.API_URL.indexOf('http://localhost') >= 0 || this.$device.ios) {
      this.muestra_cartel = false
    }
    if (!this.$device.ios) {
      // verifico si tiene permitidas las notificaciones
      if (Notification.permission === "default") {
        Notification.requestPermission().then(perm => {
          if (Notification.permission === "granted") {
            this.permiso_notif = true
          } else {
            localStorage.removeItem('subscription')
            this.permiso_notif = false
          }
        })
      }
      // tiene permiso de notificaiones
      else if (Notification.permission === "granted") {
        this.permiso_notif = true
      }
      // no tiene permiso (denied)
      else {
        localStorage.removeItem('subscription')
        this.permiso_notif = false
      }


    }

    await this.loadLocalStorage()





    // calculo la dimension del menu cerrado
    if (this.$store.state.menu) {
      this.height = (this.$store.state.menu.length) * 48
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  components: {
    NavigationDrawer,
    LoadingScreen,
    Footer,
    AyudaNotificaciones
  },
  computed: {
    ...mapState(['notificaciones']),
    ...mapGetters(['is_logged']),
    imagen() {
      return [this.$route.name == 'Login' ? {
        'background-image': `url(${require(`./assets/autito${moment().isBetween(`${moment().year()}-12-08`, `${moment().year()}-12-28`) ? '_xmas' : ''}.webp`)})`,
        'background-position': 'center',
        'background-repeat': 'no-repeat',
        'height': '500px',
        'background-size': 'cover'
      } : {
        'min-height': `${this.height}px`
      }]
    },

    notificacionesFiltradas() {
      const notificaciones = this.notificaciones.filter(notificacion => notificacion.Push == 1)
      notificaciones.forEach(notificacion => notificacion.value = true)

      return notificaciones
    }
  },
  watch: {
    // cuenta regresiva del snackbar
    '$store.state.snackbar.value'(val) {
      if (val) {
        this.start_progress()
      }
    }
  },
  methods: {
    ...mapActions(['loadLocalStorage']),
    redimensionar(size) {
      this.height = (size + this.$store.state.menu.length) * 48
    },
    reset_progress() {
      clearInterval(this.interval)
      this.value = 100
    },
    start_progress() {
      this.reset_progress()
      this.interval = setInterval(() => {
        this.value -= 10000 / this.$store.state.snackbar.timeout
      }, 100)
    },

    notificacion_leida(notificacion, redirect) {
      // elimino la notificacion del local storege
      this.$store.commit('delete_notificacion', notificacion)
      // si tiene url lo redirijo
      if (notificacion.Url && redirect) {
        window.open(notificacion.Url, '_blank')
      }
      // si no tiene url muestro modal
      else if (redirect) {
        this.$swal.fire({
          icon: 'info',
          title: notificacion.Titulo,
          text: notificacion.Mensaje
        })
      }
      // marco como leida
      this.$store.dispatch('marcar_notificacion_leida', { id: notificacion.Id })
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.theme--dark.v-card {
  background-color: var(--v-secondary-base) !important;
  color: #fff;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: var(--v-secondary-base) !important;
  color: #fff;
}

.theme--dark.v-data-table {
  background-color: var(--v-secondary-base) !important;
  color: #fff;
}

.zelda:hover {
  text-decoration: underline;
  cursor: pointer;
}

.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot {
  min-height: 32px !important;
  font-size: 14px;
}

.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box.v-select--chips--small.v-input--dense .v-select__selections,
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small.v-input--dense .v-select__selections {
  min-height: 32px !important;
}

.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer {
  margin-top: 4px !important;
}

.v-list,
.v-list--dense .v-list-item .v-list-item__content,
.v-list-item--dense .v-list-item__content {
  padding: 0px !important;
}

.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 30px !important;
}

.v-list-item__action {
  margin: 4px 8px 4px 0px !important;
}

.v-expansion-panel--active>.v-expansion-panel-header {
  min-height: 48px !important;
}

.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  /* default: 10px */
}

.v-text-field--filled .v-text-field__prefix,
.v-text-field--filled .v-text-field__suffix {
  max-height: 32px;
  margin-top: 0px !important;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  padding: 0 8px !important;
}

.cebra tr:nth-of-type(even) {
  /* con even empieza el priero con color mas claro y con odd empieza el segundo */
  background-color: rgba(0, 0, 0, .1);
}

.v-data-table tbody tr.v-data-table__selected {
  background: var(--v-rowsel-base) !important;
}

.v-chip .v-icon {
  font-size: 18px !important;
}

input[type=tel] {
  text-align: right;
}

/* Estilos de los sweetalerts2 */
.swal2-popup {
  font-family: Roboto, sans-serif !important;
  color: var(--v-swalText-base) !important;
  background: var(--v-swalBkg-base) !important;
}

/* boton confirmar */
.swal2-confirm {
  background: var(--v-success-base) !important;
}

/* boton cancelar */
.swal2-cancel {
  background: var(--v-error-base) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: right;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align: right;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.mb0 {
  background: #000;
}

.v-input__icon i.fa-search {
  font-size: 18px !important;
}

.row-selected {
  background-color: #2d6a92 !important;
}
</style>
