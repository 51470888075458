import { API_URL } from '../../../../config'
import store from '../../../store'

export default {
  namespaced: true,
  actions: {
    async getClasificaciones() {
      const response = await fetch(`${API_URL}/clasificaciones/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        }
      })

      return response.json()
    },

    async getComprasClasif({}, payload) {
      const response = await fetch(`${API_URL}/clasificaciones/compras`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async getCuentas({}, payload) {
      const response = await fetch(`${API_URL}/clasificaciones/cuentas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async nuevaClasificacionCompras({}, payload) {
      const response = await fetch(`${API_URL}/clasificaciones/nueva-compras`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    },

    async editarClasificacionCompras({}, payload) {
      const response = await fetch(`${API_URL}/clasificaciones/editar-compras`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
          Authorization: store.state.token
        },
        body: JSON.stringify(payload)
      })

      return response.json()
    }
    
  }
}
